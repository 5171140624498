import React from 'react';
import img_bg from '../assets/imgs/banner/4.jpg';
import img_5 from '../assets/imgs/home/5.jpg';
import certificate_img_piaq from '../assets/certificates/PIAQ_IMG_BIG.png';
import certificate_img_zur from '../assets/certificates/ZUR_IMG_BIG.png';
import certificate_img_scp from '../assets/certificates/SCP.png';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from '@/components/ui/button';
import Banner from '@/components/Banner';
import { BadgeCheck, Telescope } from 'lucide-react';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"

const bannerItems = [
  {
    img: img_bg,
    title: <>Über <span className='border border-yellow-hr text-yellow-hr'>Uns</span></>,
    description: <>
      Wir, die <span className="">HR Personalmanagement GmbH</span>, 
      sind ein führendes Unternehmen im Bereich der <span className="">Arbeitnehmerüberlassung</span> mit Sitz
      in <span className="">Mannheim</span>.
    </>,
    classname: 'pt-32 sm:pt-20'
  }
]

const Uberuns = () => {
  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[38vh] sm:h-[60vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Über Uns</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />

      <div className="bg-white w-full py-10 pt-10 text-slate-900 border-b-4 border-dashed border-slate-300">
        <div className="w-full text-4xl font-semibold text-slate-900 pb-4">
          <div className="relative text-center mb-5">
            <div className="absolute inset-0 flex items-center justify-center opacity-20">
              <span className="text-5xl lg:text-6xl font-bold text-gray-300">
                Über Uns
              </span>
            </div>
            <div className="relative">
              <span className="text-4xl font-bold text-blue-900">
                Über Uns
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto w-5/6 lg:w-4/6 grid grid-cols-1 lg:grid-cols-2 gap-8">
          <img src={img_5} className="w-full rounded select-none pointer-events-none" />
          <div className="lg:px-3 space-y-5 lg:text-lg flex flex-col justify-between">
            <div>
              Unser Fokus liegt darauf, Ihnen qualifizierte Arbeitskräfte zur
              Verfügung zu stellen und Sie bei der Suche nach dem richtigen
              Personal zu unterstützen.
            </div>
            <div className="text-lg italic text-yellow-hr font-semibold flex items-center space-x-1.5 lg:ps-5">
              <BadgeCheck size={23} />
              <div>Zuverlässig & Vertrauenswürdig</div>
            </div>
            <div className='lg:text-base'>
              Unser Ziel ist es, Ihnen nicht nur eine Lösung für Ihre Personalbedürfnisse zu bieten, sondern eine langfristige Partnerschaft aufzubauen.
              Wir verstehen, dass jedes Unternehmen einzigartig ist und individuelle Bedürfnisse hat. Deshalb bieten wir maßgeschneiderte Lösungen an, die auf Ihre spezifischen Anforderungen zugeschnitten sind. 
            </div>
          </div>
          <div className="lg:col-span-2">
            Dank unserer Erfahrung und unserem breiten Netzwerk können wir Ihnen qualifizierte Arbeitskräfte aus einer Vielzahl von Branchen anbieten, von München bis Bremen und darüber hinaus.
            Wir haben uns auf die Bereitstellung von Fachkräften in den Bereichen der Produktion, Büromanagement, Gesundheitswesen, Reinigung und mehr spezialisiert. Unser Team von Experten steht Ihnen bei jedem Schritt des Prozesses zur Seite, von der Bedarfsanalyse über die Auswahl geeigneter Kandidaten bis hin zur Betreuung der Mitarbeiter während ihres Einsatzes bei Ihnen.
            Wir legen großen Wert auf Qualität und Kundenzufriedenheit und sind stolz darauf, dass viele unserer Kunden uns seit vielen Jahren vertrauen.
            Wenn Sie auf der Suche nach einer zuverlässigen und erfahrenen Arbeitskräfteüberlassung sind, dann kontaktieren Sie uns gerne. Wir freuen uns darauf, Ihnen zu helfen. Mit freundlichen Grüßen, Ihr HR Personalmanagement-Team
          </div>
        </div>
      </div>

      <div className="bg-slate-900 w-full py-14 border-t-4 border-dashed border-slate-500">
        <div className="text-center text-4xl mx-auto w-5/6 lg:w-3/6 text-slate-100">
          <span className='text-yellow-hr'>Exzellenz aus Mannheim</span>: Maßgeschneiderte Personal-Lösungen für jede Branche
        </div>
      </div>
      <div className="bg-white w-full py-14 text-slate-900">
        <div className="text-3xl mx-auto w-5/6 lg:w-2/3">
          <div className="relative text-center mb-5">
            <div className="absolute inset-0 flex items-center justify-center opacity-20">
              <span className="text-5xl lg:text-6xl font-bold text-gray-300">Wichtige Dokumente und Zertifikate</span>
            </div>
            <div className="relative">
              <span className="text-4xl font-bold text-blue-900">Wichtige Dokumente und Zertifikate</span>
            </div>
          </div>
          <div className="flex space-x-5 pt-7">
            <div onClick={() => handleDownload('/certificates/Erlaubnis zur Arbeitnehmerüberlassung.pdf')} className="cursor-pointer w-60 border-4 rounded-lg transition-all hover:border-blue-400">
              <img src={certificate_img_zur} className="h-full w-full rounded-lg" />
            </div>
            <div onClick={() => handleDownload('/certificates/28.03.2024 PIAQ.pdf')} className="cursor-pointer w-60 border-4 rounded-lg transition-all hover:border-blue-400">
              <img src={certificate_img_piaq} className="h-full w-full rounded-lg" />
            </div>
            <div onClick={() => handleDownload('/certificates/Zertifikat_SCP.pdf')} className="cursor-pointer w-60 border-4 rounded-lg transition-all hover:border-blue-400">
              <img src={certificate_img_scp} className="h-full w-full rounded-lg" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-slate-900 w-full py-14">
        <div className="text-xl mx-auto w-5/6 lg:w-2/3 text-slate-100 flex items-center space-x-5">
          <Telescope size={40} />
          <div>
            Qualität und Expertise bringen die richtigen Menschen in die richtigen Positionen: Entdecken Sie <span className="text-yellow-hr font-semibold">HR Personalmanagement GmbH</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Uberuns;
